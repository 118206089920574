<template>
  <SkeletonTheme color="#e3e3e3" highlight="#c3c3c3">
    <template v-if="showFilter">
      <Skeleton height="50px" width="250px" />
    </template>
    <Skeleton height="25px" class="d-block mt-4 mb-2" />
    <div class="line-bottom"></div>
    <Skeleton height="75px" class="d-block mt-4" />
  </SkeletonTheme>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

export default {
  name: "SkeletonLoadingTable",
  components: {
    Skeleton,
    SkeletonTheme
  },
  props: {
    showFilter: {
      default: true
    }
  }
};
</script>

<style></style>
