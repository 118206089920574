<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <img :src="img" alt="empty page" width="450px" v-if="img" />
    <video autoplay muted loop id="myVideo" v-if="media">
      <source :src="media" type="video/mp4" />
    </video>
    <h6 class="text-color-black mt-0 text-center">{{ text }}</h6>
  </div>
</template>

<script>
export default {
  name: "EmptyPage",
  props: {
    img: {
      default: null
    },
    text: {
      default: null
    },
    media: {
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
h6 {
  max-width: 500px;
}
video {
  max-width: 500px;
}
</style>
