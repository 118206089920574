<template>
  <div class="">
    <TopBarTitleComponent title="Categorías" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <ButtonPrimaryComponent
            title="Nueva Categoría"
            @onSaveClick="goRouter('catalogue.categories.add')"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4">
      <template v-if="apiResponse.success">
        <template v-if="items.length">
          <section class="input-filters">
            <InputSearchComponent @onChangeSearch="onChangeSearch" />
            <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
          </section>
          <section class="table-items">
            <b-row class="table-items-header line-bottom">
              <b-col xs="12" sm="6" md="4">
                <h6 class="h6">Categoría</h6>
              </b-col>
              <b-col xs="12" sm="2" md="2">
                <h6 class="h6">Cantidad</h6>
              </b-col>
            </b-row>

            <paginate
              class="wrapper-pagination"
              name="resultQueryCategories"
              :list="resultQueryCategories"
              :per="perPaginate"
              :key="searchQuery"
            >
              <div
                class="card table-items-body"
                v-for="(item, index) in paginated('resultQueryCategories')"
                :key="index"
              >
                <div
                  class="card-body table-items-content"
                  @click="
                    goRouter('catalogue.categories.edit', { id: item.id })
                  "
                >
                  <b-row class="">
                    <b-col xs="12" sm="6" md="4" class="table-col-item">
                      <span class="font-bold str-limit-space">{{
                        item.title
                      }}</span>
                    </b-col>
                    <b-col xs="12" sm="2" md="2" class="table-col-item">
                      <span class="font-semi-bold">
                        {{ item.user_business_products.length }}
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </paginate>
            <paginate-links
              v-show="resultQueryCategories.length > perPaginate"
              for="resultQueryCategories"
              :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
              :simple="{
                next: 'Siguiente »',
                prev: '« Regresar'
              }"
            ></paginate-links>
          </section>
        </template>
        <EmptyPage
          :media="emptyMedia3"
          text="No tienes categorías registradas."
          v-else
        />
      </template>
      <LoadingSkeletonTable v-else />
    </b-container>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../components/TopBarTitle";
import InputSearchComponent from "../../../components/InputSearch";
import SelectOrderByComponent from "../../../components/SelectOrderBy";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import EmptyPage from "../../../components/EmptyPage";
import catalogueService from "../../services/catalogueService";
import { emptyMedia3 } from "@/services/resources";

export default {
  name: "CategoryListComponent",
  data: () => ({
    emptyMedia3,
    apiResponse: {
      success: false
    },
    searchQuery: null,
    orderByQuery: null,
    perPaginate: 10,
    paginate: ["resultQueryCategories"],
    items: []
  }),
  computed: {
    resultQueryCategories() {
      let categories = this.items;
      return categories
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
            case "z-a":
              return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.title.toLowerCase().includes(this.searchQuery.toLowerCase())
            : item
        );
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    async handleGetCategories() {
      try {
        let response = await catalogueService.getCategories();
        if (response.data.categories) {
          this.items = response.data.categories;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    InputSearchComponent,
    SelectOrderByComponent,
    LoadingSkeletonTable,
    ButtonPrimaryComponent,
    EmptyPage
  },
  mounted() {
    this.handleGetCategories();
  }
};
</script>

<style scoped lang="scss">
.table-items {
  .table-items-body {
    .table-items-content {
      min-height: 76px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
